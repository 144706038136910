body {
    background-color: #f1f1f1 !important;
}

.shop-nav:hover > #shop-nav-overlay {
    display: block;
}
.no-caret-dropdown:hover > .dropdown-menu {
    display: block;
}

..dropdown-menu {
    display: none;
}

.custom-nav-width
{
    width: 900px !important;
    margin-left: auto !important;
}

.team-imgbx {
    position: relative;
    /*border-radius: 30px;*/
    overflow: hidden;
}
.team-imgbx::before {
    content: "";
    /*enable this background to view black overlay*/
    /*background: black;*/
    opacity: 0.6;
    /* Add the blur effect */
    /*filter: blur(8px);*/
    /*-webkit-filter: blur(8px);*/
    position: absolute;
    /*margin-left: 1px;*/
    width: 84.5%;
    height: 0;
    transition: 0.4s ease-in-out;
}
.team-imgbx:hover::before {
    height: 100%;
}
.team-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    /*remove this to view text on overlay*/
    display: none;
    color:white;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}
.team-imgbx:hover .team-txtx {
    top: 50%;
    opacity: 1;
}
.team-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}
.team-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}


.contact-top {
    background-image: url("https://cdn.fanzine.com/assets/images/fanzine/contact-us-bg.jpg");
    height: 350px;
    width: 100%;
}

.contact-bottom {
    background-image: url("https://cdn.fanzine.com/assets/images/fanzine/contact-form-bg.jpg");
    min-height: 350px;
    width: 100%;
    margin-bottom: -3rem !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://cdn-icons-png.flaticon.com/512/60/60995.png) no-repeat;
    width: 65%;
    height: 10%;
    border: 0 !important;
}

input[type="date"]:focus-visible {
    outline: 0 !important;
}


input[type="date"]::-webkit-calendar-picker-indicator {

    content: url('https://cdn-icons-png.flaticon.com/512/60/60995.png');

}


.center-items {
    justify-content: center !important;
    align-items: center !important;
}

.heading-bold {
    font-weight: 500 !important;
}


.calendar-item {
    border: 0px;
    cursor: pointer;
}

.calendar-item-day {
    font-weight: 700;
}

.calendar-item:hover {
    font-weight: 700;
}

.bg-black {
    background-color: #000000 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-grey {
    background-color: #c2c2c2 !important;
}

.bg-background {
    background-color: #f1f1f1 !important;
}

.boder-radius-15px {
    border-radius: 15px !important;
}


.DatePicker_dateDayItem__ZFl6u {
    border-radius: 8px !important;
    height: 50px !important;
}

.border-radius-10px {
    border-radius: 10px !important;
}

.border-radius-top-15px {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.border-radius-bottom-15px {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}
.border-radius-15px {
    border-radius: 15px !important;
}

.border-radius-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.form-control:focus{
    box-shadow: none !important;
}

.font-36 {
    font-size: 36px !important;
}

.font-40 {
    font-size: 40px;
}

.font-48 {
    font-size: 48px;
}

.font-50 {
    font-size: 50px;
}

.font-53 {
    font-size: 53px;
}


.font-58 {
    font-size: 58px;
}


.font-60 {
    font-size: 60px;
}
.font-62 {
    font-size: 62px;
}
.font-64 {
    font-size: 64px;
}
.font-65 {
    font-size: 65px;
}
.font-70 {
    font-size: 70px;
}

.font-75 {
    font-size: 75px;
}


.font-76 {
    font-size: 76px;
}


.font-79 {
    font-size: 79px;
}

.font-80 {
    font-size: 80px;
}

.font-nft-overlay-title {
    font-size: 90px;
}


.dropdown-item:hover{
    font-weight: 800 !important;
}






/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .nav-mega-menu-fanzine {
        width: 95vw !important;
        margin-left: -38vh !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }
    .nav-mega-menu-fanzine-1 {
        width: 95vw !important;
        margin-left: -38vh !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }


}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    .nav-mega-menu-fanzine {
        width: 85vw !important;
        margin-left: -48vh !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }
    .nav-mega-menu-fanzine-1 {
        width: 85vw !important;
        margin-left: -48vh !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

    .nav-mega-menu-fanzine {
        width: -webkit-fill-available !important;
        margin-left: 0 !important;
        position: fixed;
        left: 25% !important;
        margin-top: -5px !important;
        padding-top: 55px !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }

    .nav-mega-menu-fanzine-1 {
        width: -webkit-fill-available !important;
        margin-left: 0 !important;
        position: fixed;
        left: 25% !important;
        margin-top: -5px !important;
        padding-top: 55px !important;
        padding-bottom: 1em;
        max-height: 70%;
        overflow: auto;
    }


}

.nav-content-fanzine {
    width: 100% !important;
}

.fanzine-color {
    color: #24722f !important;
}

.card-img-overlay {
    border-radius: 0 !important;
}

.news-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    margin-top: auto;
    margin-bottom: 0;
}

.video-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url('../../assets/images/play_button.png') no-repeat content-box center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    background-size: contain;
    margin-top: auto;
    margin-bottom: 0;
}

.video-overlay-slider {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url('../../assets/images/play_button.png') no-repeat content-box center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    height: 80%;
    background-size: contain;
    margin-top: auto;
    margin-bottom: 0;
}

.fs-video-overlay-slider {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%), url('../../assets/images/play_button.png') no-repeat content-box center;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    height: 50%;
    background-size: contain;
    margin-top: 0;
    margin-bottom: auto;
}


.news-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 60px;
    max-height: 60px;
}

.match-card {
    height: 250px;
    color: #fff;
    padding: 15px 31px 18px 20px;
    /*border: solid 1px #fff;*/
    /*border-radius: 15px;*/
    background-size: cover !important;
    /*justify-content: center;*/
}

.slider-mobile-container {
    display: flex;
    /*overflow-x: scroll;*/
    width: 100%;
}

.overflow-scroll-x {
    overflow-x: scroll !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.slider-mobile-container {
    scrollbar-width: auto;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

/* Chrome, Edge, and Safari */
.slider-mobile-container::-webkit-scrollbar {
    width: 500px !important;
    height: 10px;
}

.slider-mobile-container::-webkit-scrollbar-track {
    background: transparent;
}

.slider-mobile-container::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
}

.slider-mobile-match-card {
    flex-shrink: 0;
    width: 475px;
    height: 100%;
    margin-left: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.responsive-navbar-nav-fanzine .dropdown-menu {
    border: none !important;
    left: 0 !important;
    width: 100% !important;
    background: transparent !important;
}

.responsive-navbar-nav-fanzine {
    /*padding-left: 10px !important;*/
    width: 100% !important;
    text-align: center;
    background-color: #000000;

}

.bm-burger-button {
    display: none !important;
}

.navbar-toggler {
    /*position: absolute;*/
    left: 0;
    top: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    float: right !important;
    /*margin: 0;*/
    /*padding: 0;*/
    border: none !important;
    font-size: 0;
    background: transparent;
    cursor: pointer;
}

.bm-overlay {
    position: fixed;
    z-index: 1000;
    width: 100% !important;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    /*transform: translate3d(100%, 0px, 0px);*/
    transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
    top: 0 !important;
    left: 0 !important;
    display: none;
}

.iframe-content {
    top: 25px;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    position: fixed;
}

.iframe-content > iframe {
    height: 100%;
    width: 100%;
}

.iframe-content-nft {
    top: 0;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    position: fixed;
}

.iframe-content-nft > iframe {
    height: 100%;
    width: 100%;
}

.mt-n10 {
    margin-top: -10px !important;
}

.fanzine-slider-arrow:before {
    color: rgba(0, 0, 0, 0.75) !important;
    font-size: 75px !important;
    font-weight: 100 !important;
    line-height: 0 !important;
}

.text-decoration-underline-fanzine {
    text-decoration: none;
}

.text-decoration-underline-fanzine:active, .text-decoration-underline-fanzine.active {
    text-decoration: underline;
    text-underline-offset: 18px;
    text-decoration-thickness: 7px;
}

.font-italic {
    font-style: italic !important;
}

.shadow {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.carousel-indicators [data-bs-target] {
    height: 16px !important;
    width: 16px !important;
    border-radius: 50% !important;

}

.carousel-indicators [data-bs-target] :active {
    background: black !important;
}


@media only screen and (max-width: 600px) {

    .slider-mobile-match-card {
        flex-shrink: 0;
        width: 350px;
        height: 100%;
        margin-left: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .match-card {
        height: 200px;
        color: #fff;
        padding: 15px 31px 18px 20px;
        /*border: solid 1px #fff;*/
        /*border-radius: 15px;*/
        background-size: cover !important;
        /*justify-content: center;*/
    }

    .pt-n150 {
        margin-top: -150px !important;
    }

    .responsive-navbar-nav-fanzine .dropdown-menu {
        border: none !important;
        left: 0 !important;
        width: 100% !important;
        background: transparent !important;
    }

    .fanzine-nav-responsive.show {
        /*background-color: darkgrey !important;*/
        padding: 0 !important;
    }

    .fanzine-nav-responsive .nav-link, .fanzine-nav-responsive .nav-link:hover {
        color: #000000;
    }

    .border-bottom-5 {
        height: 50px !important;
        border-bottom: 5px solid #dee2e6 !important;
    }

    .font-nft-overlay-title {
        font-size: 50px;
    }

}

.slick-track {
    display: flex !important;
}

.player-unavailable
{
    border-radius: 50px !important;
    border: 3px solid white !important;
    box-shadow: 0px 0px 9px black !important;
}

#nobgvideoSliderContainer1
{
    overflow: hidden !important;
}
