.bg-red {
    background-color: #e20001;
}

.bg-hover-grey
{
    background: #fff;
}

.bg-hover-grey:hover{
    background: #f3f3f3 !important;
    color: black !important;
}

.rounded-top-custom {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important
}

.rounded-bottom-custom {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important
}

.text-light-black {
    color: #2a2a2a;
}

.text-light-gray-single-news {
    color: #666;
}

.social-list {
    height: 950px;
    overflow: auto;
}

.social-list-long {
    max-height: 1900px;
    overflow: auto;
}

/*.social-list li { background-color: #f6f6f6; }*/
/*.social-list li:nth-child(odd) { background-color: #f6f6f6; }*/

.icon-height {
    height: 50px;
}




/* ===== Scrollbar CSS ===== */
/* Firefox */
/*
.social-list {
    scrollbar-width: auto;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

!* Chrome, Edge, and Safari *!
.social-list::-webkit-scrollbar {
    width: 16px;
}

.social-list::-webkit-scrollbar-track {
    background: #e9e9e9;
}

.social-list::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 50px;
    border: 3px solid #e9e9e9;
}

!* ===== Scrollbar CSS ===== *!
!* Firefox *!
.social-list-long {
    scrollbar-width: 200px;
    scrollbar-color: #c2c2c2 #e9e9e9;
}

!* Chrome, Edge, and Safari *!
.social-list-long::-webkit-scrollbar {
    width: 160px;
    border-radius: 50%;
    border: 3px solid red !important;
}

.social-list-long::-webkit-scrollbar-track {
    background: #e9e9e9;
}

.social-list-long::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 50px;
    border: 3px solid #e9e9e9;
}*/
