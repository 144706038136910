.h-25px
{
    height: 25px !important;
}

.w-25px
{
    width: 25px !important;
}

.h-50px
{
    height: 50px !important;
}

.w-50px
{
    width: 50px !important;
}
